import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { useAuth0 } from '@auth0/auth0-react';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Indicator from './components/Indicator'; 
import Live_img from './assets/live.png';

const VideoPlayer = () => {
    const videoRef = useRef(null);
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const [hlsInstance, setHlsInstance] = useState(null);
    const [levels, setLevels] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(-1); // -1 for auto
    const [metrics, setMetrics] = useState({
        bufferLength: 0,
        bitrate: 0,
        droppedFrames: 0,
        decodedFrames: 0,
    });
    const [videoList, setVideoList] = useState([]);
    const [filteredVideoList, setFilteredVideoList] = useState([]);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoName, setVideoName] = useState('');
    const [videoCategory, setVideoCategory] = useState('');
    const [videoDescription, setVideoDescription] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [categories, setCategories] = useState([]);
    const [token, setToken] = useState('');
    const [sliderBackground, setSliderBackground] = useState('#007bff'); // Default color

    const serverUrl = process.env.REACT_APP_SERVER_URL;

    const videoDataLive = [
        {
            "icon": Live_img,
            "playlist": "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8",
            "category": ["Live"],
            "id": "live1",
            "name": "Live Akamai",
            "description": "Experience real-time events and exclusive content on the 'Live Akamai' channel...",
            "profile": "Matlala grp",
            "created_at": "2024-07-20T14:34:00.683Z",
            "width": 640,
            "height": 360
        },
    ];

    const fetchVideoData = async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                setToken(token);

                const response = await fetch(`${serverUrl}/api/content`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();

                // Process video icons
                for (const item of data) {
                    try {
                        item.icon = await fetch(item.icon, {
                            method: 'GET',
                            mode: 'cors',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        })
                          .then((res) => res.blob())
                          .then((blob) => URL.createObjectURL(blob));
                    } catch (e) {
                        console.error('Error processing icon:', e.message);
                    }
                }

                // Combine data with live video data
                const combinedData = data.concat(videoDataLive);

                // Extract categories
                const allCategories = new Set();
                combinedData.forEach(video => {
                    video.category.forEach(cat => allCategories.add(cat.trim()));
                });
                setCategories(['All', ...Array.from(allCategories)]);

                setVideoList(combinedData);
                setFilteredVideoList(combinedData);

                console.log("EEE--->",combinedData);

                if (combinedData[0]) {
                    setVideoUrl(combinedData[0].playlist);
                    setVideoName(combinedData[0].name);
                    setVideoDescription(combinedData[0].description);
                    setVideoCategory(combinedData[0].category[0]);
                }
            } catch (e) {
                console.error('Fetch video data error:', e.message);
                console.log("EEE",e);
            }
        }
    };

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            fetchVideoData();
        }
    }, [isLoading, isAuthenticated, getAccessTokenSilently, serverUrl]);

    useEffect(() => {
        let hls;

        if (Hls.isSupported() && videoUrl && videoRef.current) {
            hls = new Hls({
                startLevel: 0,  // Start at the lowest quality
                maxMaxBufferLength: 3,  // Limit buffer length to 3 seconds
            });

            // Apply authorization token only if the video URL is not from the live stream source
            if (!videoUrl.includes('akamaized.net')) {
                hls.config.xhrSetup = (xhr) => {
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
                };
            }
            hls.loadSource(videoUrl);
            hls.attachMedia(videoRef.current);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                setLevels(hls.levels);
            });

            hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
                setCurrentLevel(data.level);
            });

            const updateMetrics = () => {
                if (videoRef.current && hls) {
                    const buffered = videoRef.current.buffered;
                    let bufferLength = 0;
                    for (let i = 0; i < buffered.length; i++) {
                        if (buffered.start(i) <= videoRef.current.currentTime && videoRef.current.currentTime <= buffered.end(i)) {
                            bufferLength = buffered.end(i) - videoRef.current.currentTime;
                            break;
                        }
                    }
                    setMetrics({
                        bufferLength,
                        bitrate: hls.bandwidthEstimate,
                        droppedFrames: videoRef.current.getVideoPlaybackQuality
                            ? videoRef.current.getVideoPlaybackQuality().droppedVideoFrames
                            : videoRef.current.webkitDroppedFrameCount,
                        decodedFrames: videoRef.current.getVideoPlaybackQuality
                            ? videoRef.current.getVideoPlaybackQuality().totalVideoFrames
                            : videoRef.current.webkitDecodedFrameCount,
                    });
                }
            };

            const metricsInterval = setInterval(updateMetrics, 1000);
            setHlsInstance(hls);

            return () => {
                hls.destroy();
                clearInterval(metricsInterval);
            };
        } else if (videoRef.current && videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = videoUrl;
            videoRef.current.addEventListener('loadedmetadata', () => {
                videoRef.current.play();
            });
        }
    }, [videoUrl, token]);

    useEffect(() => {
        if (levels.length > 0) {
            const mediaBitrate = levels[currentLevel]?.bitrate || 0;
            const color = calculateRangeColor(metrics.bitrate, mediaBitrate);
            setSliderBackground(color);
        }
    }, [metrics.bitrate, currentLevel, levels]);

    const calculateRangeColor = (bitrate, mediaBitrate) => {
        if (bitrate < mediaBitrate * 0.5) return '#dc3545'; // Red for low bitrate
        if (bitrate < mediaBitrate * 1.5) return '#ffc1076e'; // Yellow for medium bitrate
        return '#28a74561'; // Green for high bitrate
    };

    const handleLevelChange = (event) => {
        const selectedLevel = parseInt(event.target.value, 10);
        setCurrentLevel(selectedLevel);
        if (hlsInstance) {
            hlsInstance.currentLevel = selectedLevel;
        }
    };

    const handleVideoSelection = (video) => {
        setVideoUrl(video.playlist);
        setVideoName(video.name);
        setVideoDescription(video.description);
        setVideoCategory(video.category[0]);
        videoRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const calculateDataUsage = (bitrate) => {
        const mbps = bitrate / 1000000;
        const dataUsagePerMinute = mbps * 60;
        return dataUsagePerMinute / 8;
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        if (category === 'All') {
            setFilteredVideoList(videoList);
        } else {
            const filtered = videoList.filter(video => video.category.includes(category.trim()));
            setFilteredVideoList(filtered);
        }
    };

    return (
        <div>
            <div className="container"> 
                {isAuthenticated && (   
                    <div className="row vid_plyer_page">
                        <div className="col-md-8 current_vid_details">
                            <video ref={videoRef} controls style={{ width: '100%' }} />
                            <div className='vid_details_block'>
                                <h4>{videoName}</h4>
                                <p>{videoDescription}</p>
                                <span className='vid_category'><p><i>Category: {videoCategory}</i></p></span>
                            </div>
                            {levels.length > 0 && (
                                <div>
                                    <div className='row vid_details_block'>
                                        <div className='col-sm-2'>
                                            <label htmlFor="quality-control">Quality: </label>
                                            <select
                                                id="quality-control"
                                                value={currentLevel}
                                                onChange={handleLevelChange}
                                                className='form-select form-select-sm'
                                            >
                                                <option value="-1">Auto</option>
                                                {levels.map((level, index) => (
                                                    <option key={index} value={index}>
                                                        {`${(level.bitrate / 1000).toFixed(0)} kbps`}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-9'>
                                        <label htmlFor="quality-control">Bitrate: </label>
                                            <input
                                                type="range"
                                                id="bitrate-control"
                                                min="0"
                                                max={levels.length - 1}
                                                value={currentLevel}
                                                onChange={handleLevelChange}
                                                className='form-range'
                                                style={{
                                                    background: sliderBackground,
                                                }}
                                            />
                                        </div>
                                        <div className='col-sm-1 indi'>
                                            <Indicator netBitrate={metrics.bitrate} mediaBitrate={videoUrl ? levels[currentLevel]?.bitrate || 0 : 0} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <h5>Metrics</h5>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Buffer Length: {metrics.bufferLength.toFixed(2)} seconds</p>
                                                <p>Bitrate: {(metrics.bitrate / 1000).toFixed(2)} kbps</p>
                                                <p>Dropped Frames: {metrics.droppedFrames}</p>
                                                <p>Decoded Frames: {metrics.decodedFrames}</p>
                                                <p>Data Usage per Minute: {calculateDataUsage(metrics.bitrate).toFixed(2)} MB</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">            
                            <div id="category-scroll-container">
                                <Stack direction="horizontal" gap={2} id="category-chips">
                                    {categories.map((category, index) => (
                                        <Badge
                                            key={index}
                                            bg={selectedCategory === category ? "primary" : "secondary"}
                                            onClick={() => handleCategoryClick(category)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {category}
                                        </Badge>
                                    ))}
                                </Stack>
                            </div>
                            <h5 className="mt-3 vd_list_h4">Video List</h5>
                            <div className="container videos_list">
                                {filteredVideoList.map((video, index) => (
                                    <div className="col-sm-12 vid_icon mb-3" 
                                        key={index}
                                        onClick={() => handleVideoSelection(video)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className='row vid_row'>
                                            <div className='col-sm-4 vid_icon_pic'>
                                                <img src={video.icon} alt={`Thumbnail for ${video.name}`} className="img-thumbnail" />
                                            </div>
                                            <div className='col-sm-8 vid_details'>
                                                <p>{video.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoPlayer;
